export const RANK_COLORS: Record<number, LumosColor> = {
  0: 'coolGray82',
  1: 'kaleBase',
  2: 'tealBase',
  3: 'orangeBase',
  4: 'tomatoBase',
  5: 'plumBase',
}

export const RANKS_KEYED_BY_NUMBER: Record<number | string, string> = {
  0: 'noBadges',
  1: 'newcomer',
  2: 'explorer',
  3: 'strategist',
  4: 'brainiac',
  5: 'mastermind',
} as const

export const RANKS_KEYED_BY_NAME: Record<string, number> = {
  newcomer: 1,
  explorer: 2,
  strategist: 3,
  brainiac: 4,
  mastermind: 5,
}

export const REQUIRED_PLAYS: Record<number | string, number> = {
  1: 3,
  2: 5,
  3: 10,
  4: 15,
  5: 20,
} as const

export const anonymousUserHelpCenterUrl = `https://lumositybeta.zendesk.com/hc`
export const loggedInUserHelpCenterUrl = `/api/helpCenter`

export const FOOTER_LINKS = {
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  californiaPrivacy: '/privacy-policy#what-information-we-collect',
  help: anonymousUserHelpCenterUrl,
} as const

export const SUPPORT_EMAIL = 'accounts@lumosity.com'

export const PUBLIC_PATHS = ['/login', '/author', '/landing', '/privacy-policy', '/terms-of-service', '/payment-policy']
// Don't redirect to the onboard survey from these paths
export const PRE_FIT_TEST_PATHS = [
  '/onboard',
  '/fit-test',
  '/settings',
  '/subscribe',
  '/payment-confirmation',
  '/checkout',
  ...PUBLIC_PATHS,
]

export const UNKNOWN_STAT_KEY = 'unknown'

export const LUMOSITY_USER_COOKIE = 'lumosityUser'
export const FLAGSHIP_USER_COOKIE = '_lumosity_session'
export const DUMMY_USER_ID = '96b0cff4-d219-4d25-b38c-199ab279a591'

export enum GameListFilter {
  byArea,
  byBadge,
}

export const FitTestGame = {
  'color-match': 'color-match',
  'train-of-thought': 'train-of-thought',
  'memory-matrix': 'memory-matrix',
} as const

export enum PlayContext {
  Workout = 'workout',
  FreePlay = 'games',
  FitTest = 'fit_test',
}

export const PLATFORM_WEB = 'web'

export const LIMITED_WORKOUT_GAME_TOTAL = 3
export const PREMIUM_WORKOUT_GAME_TOTAL = 10

// See https://github.com/lumoslabs/lumosity-frontend#number-of-games
export const BASE_COUNT_EN_WEB_GAMES = 40
export const BASE_COUNT_INTL_WEB_GAMES = 40

export enum HeaderTypes {
  lumos = 'lumos',
  freeplayPreGame = 'freeplayPreGame',
  freeplayPostGame = 'freeplayPostGame',
  postWorkoutGame = 'postWorkoutGame',
  survey = 'survey',
  login = 'login',
  preWorkoutGame = 'preWorkoutGame',
  preFitTestGame = 'preFitTestGame',
  stepGame = 'stepGame',
  empty = 'empty',
  subscribe = 'subscribe',
  noTopNav = 'noTopNav',
}

export const skipQuestionResponse = 'unspecified'

// These are the areas displayed in Your LPI in the Stats page
export enum AreaSlugWithLPI {
  'attention' = 'attention',
  'flexibility' = 'flexibility',
  'memory' = 'memory',
  'problem-solving' = 'problem-solving',
}

// These areas will not be displayed in Your LPI in the Stats page
enum AreaSlugsNotIncludedInOverallLPI {
  'language' = 'language',
  'math' = 'math',
}

// This is used for organizing games by area on the Games page
export const AreaSlugs = { ...AreaSlugWithLPI, ...AreaSlugsNotIncludedInOverallLPI }

export const supportedCurrencies = ['USD', 'CAD', 'AUD', 'GBP', 'EUR', 'JPY', 'MXN']

export const PaymentProviderLabels = {
  apple: 'Apple',
  google: 'Google',
  stripe: 'Credit Card',
  braintree: 'PayPal',
  lumos: 'Lumosity',
} as const

export const appleSubscriptionManagementLink = 'https://apps.apple.com/account/subscriptions'

export const appleSubscriptionManagementFaqLink = 'https://support.apple.com/kb/HT4098'

export const googleSubscriptionManagementLink =
  'https://play.google.com/store/account/subscriptions?package=com.lumoslabs.lumosity'

export const googleSubscriptionManagementFaqLink = 'https://support.google.com/googleplay/answer/2476088'
