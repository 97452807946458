
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react'

import { GlobalStyle } from '@lumoslabs/lumosity-storybook'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { CookiesProvider } from 'react-cookie'
import AuthProvider from 'src/components/auth/AuthProvider'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { ModalProvider } from 'styled-react-modal'

import { FeaturesProvider } from '~/components/features/FeaturesProvider'
import MarketingProvider from '~/components/marketing/MarketingProvider'
import StyledModalBackground from '~/components/modals/StyledModalBackground'
import Rollbar from '~/components/Rollbar'
import RouteGuard from '~/components/RouteGuard'
import SnackbarProvider from '~/components/snackbars/SnackbarProvider'
import AnalyticsProvider from '~/context/AnalyticsContext'
import { lightTheme } from '~/styles/theme'
import { composeComponents } from '~/utils/composeComponents'

// Note: SideNav appearance is now determined in withApollo, Footer is included either in AppLayout or on a page-by-page basis

const ComposedProvider = composeComponents([
  CookiesProvider,
  AuthProvider,
  FeaturesProvider,
  SnackbarProvider,
  RouteGuard,
  MarketingProvider,
  AnalyticsProvider,
])

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  return (
    <>
      <Normalize />
      <Head>
        <title>Lumosity</title>
        <meta name='robots' content='noindex,nofollow' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Rollbar />
      </Head>
      <Script id='google-tag-manager' strategy='afterInteractive'>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MV4V9N3');
          `}
      </Script>
      <ThemeProvider theme={lightTheme}>
        <ComposedProvider>
          <GlobalStyle />
          <ModalProvider backgroundComponent={StyledModalBackground}>
            <Component {...pageProps} />
          </ModalProvider>
        </ComposedProvider>
      </ThemeProvider>
    </>
  )
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  